import { graphql, useStaticQuery } from "gatsby"

export default assetUrl => {
  const { allContentfulAsset } = useStaticQuery(
    graphql`
      query {
        allContentfulAsset(filter: { file: { url: { ne: null } } }) {
          nodes {
            file {
              url
            }
            fluid(
              maxHeight: 480
              maxWidth: 854
              quality: 60
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    `
  )
  const a = allContentfulAsset.nodes.filter(
    n => JSON.stringify(n.file.url) === assetUrl
  )[0].fluid

  return a
}
